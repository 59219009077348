@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~@ng-select/ng-select/themes/default.theme.css";

// Move this outside the tailwind layers as its dynamically loaded
@import "./app/shared/styles/components/_date-picker.scss";

@import "./app/shared/styles/_fonts.scss";
@import "./app/shared/styles/_mixins.scss";

// Tailwind base layer
@layer base {
  @import "./app/shared/styles/_base.scss";
}

// Tailwind components layer
@layer components {
  @import "./app/shared/styles/components/_badge.scss";
  @import "./app/shared/styles/components/_float-ui.scss";
  @import "./app/shared/styles/components/_buttons.scss";
  @import "./app/shared/styles/components/_links.scss";
  @import "./app/shared/styles/components/_ng-select.scss";
}

// Tailwind utilities layer
@layer utilities {
  @import "./app/shared/styles/_utils.scss";
}

// Use invisible instead of hidden so that reCAPTCHA still works as it should
.grecaptcha-badge {
  @apply invisible;
}

// To help with the router-outlet animation
router-outlet ~ * {
  width: 100%;
  height: 100%;
}

// We cant unfortunately provide Tailwind with the maximum line height (app-patient/patient/tailwind.settings.fontSizes.js) for each breakpoint so we set them here
.text-xs {
  @apply sm:leading-5 #{!important};
}

.text-sm {
  @apply sm:leading-6 #{!important};
}

.text-base {
  @apply sm:leading-7 #{!important};
}

.text-lg {
  @apply sm:leading-7 #{!important};
}

.text-xl {
  @apply sm:leading-8 #{!important};
}

.text-2xl {
  @apply sm:leading-9 #{!important};
}

.text-3xl {
  @apply sm:leading-10 #{!important};
}

.text-4xl,
.text-5xl,
.text-6xl,
.text-7xl,
.text-8xl,
.text-9xl {
  @apply sm:leading-none #{!important};
}
